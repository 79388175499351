import {gql} from "@apollo/client";

const LOGIN = gql`
    query login($input:Login){
        login(input:$input){
            _id
            email
            name
        }
    }
`

export {LOGIN}