import {gql} from "@apollo/client";

const QUIZZES = gql`
    query quizzes($query:QuizQueryInput){
        quizzes(query:$query){
            _id
            title
            type
            answers
        }
    }
`

export {QUIZZES}