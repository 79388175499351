import {gql} from "@apollo/client";

const SPEAKERS = gql`
    query speakers($sortBy:SpeakerSortByInput!,$query:SpeakerQueryInput){
        speakers(sortBy:$sortBy,query:$query){
            _id
            name
            position
            url
        }
    }
`

export {SPEAKERS}