import React from 'react';
import {Card, Col, Row, Spin} from "antd";
import {useSpeakers} from "../../hooks/speakers";
import Seo from "../seo";
import {StaticImage} from "gatsby-plugin-image";

const {Meta} = Card
export default function Speakers() {
  const {speakers, loading} = useSpeakers()
  return (
    <Row justify="center" gutter={[15, 15]}>
      <Seo title="Orateurs"/>
      <Col sm={18} xs={0}>
        <StaticImage
          src="../../images/speakerBanner.png"
          alt="speakerBanner"
        />
      </Col>
      <Col sm={18} xs={23}>
        <Row gutter={[8, 8]}>
          {loading ?
            <Spin size="large" style={{color: "white"}}/> :
            speakers.map(speaker => (
              <Col xs={24} sm={6} key={speaker._id}>
                <Card
                  cover={<img src={speaker.url} alt={speaker.name}
                              loading="lazy" width={300} height={300}/>}
                  style={{height: "100%"}}
                >
                  <Meta title={speaker.name} description={speaker.position}/>
                </Card>
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
}