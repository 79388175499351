import React from 'react';
import Seo from "../seo";
import {Card, Col, Row, Space} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import "../../styles/partners.css"
export default function Partners() {
  return (
    <div>
      <Seo title="Partenaires"/>
      <Row justify="center" gutter={[15, 15]}>
        <Col sm={18} xs={23}>
          <Space direction="vertical" size="middle">
            <Row gutter={[15, 15]} justify="center">
              <Col sm={10} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 280}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/sanofi.png"
                        alt="sanofi"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={10} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 280}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/novonordisk.png"
                        alt="novo"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row  gutter={[15, 15]} justify="center">
              <Col sm={6} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 190}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/airp.png"
                        alt="airp"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={6} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 190}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/ipsen.png"
                        alt="ipsen"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={6} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 190}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/sandoz.png"
                        alt="sandoz"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={6} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 190}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/merck.png"
                        alt="merck"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
           
            <Row gutter={[15, 15]} justify="center">
              <Col sm={4} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 130}}>
                    <Col sm={18} xs={10}>
                      <StaticImage
                        src="../../images/logos/procomed.png"
                        alt="procomed"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={4} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 130}}>
                    <Col sm={18} xs={10}>
                      <StaticImage
                        src="../../images/logos/hikma.png"
                        alt="hikma"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={4} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 130}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/servier.png"
                        alt="servier"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={4} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 130}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/vitalcare.png"
                        alt="vitalcare"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={4} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 130}}>
                    <Col sm={18} xs={13}>
                      <StaticImage
                        src="../../images/logos/medtronic.png"
                        alt="medtronic"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={4} xs={24}>
                <Card hoverable className="card-layout">
                  <Row justify="center" align="middle" style={{height: 130}}>
                    <Col span={18}>
                      <StaticImage
                        src="../../images/logos/neomedic.png"
                        alt="neomedic"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
    </div>
  );
}