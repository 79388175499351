import {gql} from "@apollo/client";

const INSERT_QUESTION = gql`
    mutation insertOneQuestion($data:QuestionInsertInput!){
        insertedQuestion:insertOneQuestion(data:$data){
            _id
        }
    }
`

export {INSERT_QUESTION}