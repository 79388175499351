import React, {useState} from 'react';
import "../../../../styles/player.css"
import ReactPannellum from "react-pannellum";
import novo from "./Novonordisk.jpg"
import preview from "./preview.jpg"
import {Card, Col, Grid, List, Modal, Row} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import {PlayCircleOutlined} from "@ant-design/icons";
import Seo from "../../../seo";

const {useBreakpoint} = Grid
const urls = [
  {
    title: "Nordiflex",
    url: "https://jed-beo.visiocare.org/stands/novonordisk/nordiflex.mp4"
  },
  {
    title: "Norditropine 30ANS",
    url: "https://jed-beo.visiocare.org/stands/novonordisk/norditropine-30ans.mp4"
  },
  {
    title: "Novomix 30 Dates Clés",
    url: "https://jed-beo.visiocare.org/stands/novonordisk/novomix30-dates-cle.mp4"
  },
  {
    title: "Novomix 30",
    url: "https://jed-beo.visiocare.org/stands/novonordisk/novomix30.mp4"
  },
  {
    title: "Novorapid",
    url: "https://jed-beo.visiocare.org/stands/novonordisk/novorapid.mp4"
  }
]

export default function Sanofi() {
  const screens = useBreakpoint()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("https://jed-beo.visiocare.org/stands/novonordisk/nordiflex.mp4");
  
  function showContent(content) {
    setContent(content)
    setIsModalVisible(true)
  }
  
  function getModalWidth() {
    if (screens.xs) {
      return "100%"
    } else {
      switch (content) {
        case "Novomix 30":
        case "Levemir Novo Rapid":
        case "Novo Rapid":
        case "Levemir":
        case "Victoza":
        case "NordiFlex":
          return "35%";
        default:
          return 1500
      }
    }
  }
  
  return (
    <>
      <Seo title="Novonordisk"/>
      <ReactPannellum
        id="1"
        sceneId="firstScene"
        imageSource={novo}
        config={{
          //hotSpotDebug: true,
          autoRotate: false,
          autoLoad: true,
          showControls: false,
          preview: preview,
          //hfov: 130,
          hotSpots: [
            {
              type: "custom",
              pitch: -0.2742076913076599,
              yaw: 16.36657134529176,
              text: "Levemir Novo Rapid",
              clickHandlerFunc: () => showContent("Levemir Novo Rapid"),
              cssClass: "linkHotSpot"
            },
            {
              type: "custom",
              pitch: -0.2742076913076599,
              yaw: -15.464482405035769,
              text: "Novomix 30",
              cssClass: "linkHotSpot",
              clickHandlerFunc: () => showContent("Novomix 30"),
            },
            {
              type: "custom",
              pitch: -4.301408905343751,
              yaw: -22.780032130268147,
              text: "Novo Rapid",
              cssClass: "linkHotSpot",
              clickHandlerFunc: () => showContent("Novo Rapid"),
            },
            {
              type: "custom",
              pitch: 0.30473793449734526,
              yaw: -33.58324595719823,
              text: "NordiFlex",
              cssClass: "linkHotSpot",
              clickHandlerFunc: () => showContent("NordiFlex"),
            },
            {
              type: "custom",
              pitch: 0.5635390988373815,
              yaw: 35.351018161379805,
              text: "Victoza",
              cssClass: "linkHotSpot",
              clickHandlerFunc: () => showContent("Victoza"),
            },
            {
              type: "custom",
              pitch: -3.740526265741502,
              yaw: 24.147950698309284,
              text: "Levemir",
              cssClass: "linkHotSpot",
              clickHandlerFunc: () => showContent("Levemir"),
            },
            {
              type: "custom",
              pitch: 0.04063510863807973,
              yaw: 1.219145457685685,
              text: "Vidéos",
              cssClass: "playHotSpot",
              clickHandlerFunc: () => showContent("Video"),
            }
          ]
        }}
        autoLoad={true}
        style={{
          width: "100%",
          height: "92vh"
        }}
      />
      <Modal
        title={<p>{content}</p>}
        centered
        width={getModalWidth()}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose={content === "Video"}
      >
        {content === "Novomix 30" &&
          <StaticImage src="./novomix.jpg" alt="apidra"/>}
        {content === "NordiFlex" &&
          <StaticImage src="./nordiflex.jpg" alt="nordiflex"/>}
        {content === "Victoza" &&
          <StaticImage src="./victoza.jpg" alt="victoza"/>}
        {content === "Levemir" &&
          <StaticImage src="./levemir.jpg" alt="levemir"/>}
        {content === "Levemir Novo Rapid" &&
          <StaticImage src="./pregnant.jpg" alt="levemir2" />}
        {content === "Novo Rapid" &&
          <StaticImage src="./15ans.jpg" alt="novorapid" />}
        {content === "Video" &&
          
          <Row gutter={10}>
            <Col xs={24} sm={17}>
              <ReactPlayer width="100%" height="100%" controls url={url}
                           playing/>
            </Col>
            <Col xs={24} sm={7}>
              <Card style={{height: "100%"}}>
                <List
                  dataSource={urls}
                  renderItem={item => (
                    <List.Item
                      style={url === item.url ? {backgroundColor: "rgba(0,0,0,0.1)"} : {}}
                      onClick={() => setUrl(item.url)}
                    >
                      <List.Item.Meta
                        avatar={<PlayCircleOutlined/>}
                        title={item.title}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        }
      </Modal>
    </>
  );
}
