import React from 'react';
import Seo from "../seo";
import {Card, Col, Grid, Row, Typography} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import "../../styles/list.css"

const {Title} = Typography
const {useBreakpoint} = Grid

export default function Home() {
  const screens = useBreakpoint()
  return (
    <div>
      <Seo title="Accueil"/>
      <br/>
      <Row justify="center">
        <Col xs={23} sm={18}>
          {screens.xs ?
            <StaticImage
              src="../../images/mobileBanner.png"
              alt="banner"
            /> :
            <StaticImage
              src="../../images/banner.jpg"
              alt="banner"
            />}
        </Col>
      </Row>
      <br/>
      <br/>
      <Row justify="center">
        <Col xs={23} sm={18}>
          <Title
            style={{textAlign: "center", color: "rgb(0,6,98)"}}>Thèmes</Title>
          <br/>
        </Col>
        <Col xs={23} sm={18}>
          <Row gutter={[45, 45]}>
            <Col xs={24} sm={12}>
              <Card
                style={{borderRadius: 20, fontSize: 20, color: "rgb(0,6,98)",boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.4)"}}>
                <ul className="ul">
                  <li>Obésité</li>
                  <li>Technologie et Diabète</li>
                  <li>Complications du Diabète</li>
                  <li>Coeur et Endocrinopathies (dysthyroïdie, Acromégalie,
                    Diabète sucré, syndrome de Cushing)
                  </li>
                </ul>
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card
                style={{borderRadius: 20, fontSize: 20, color: "rgb(0,6,98)",boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.4)"}}>
                <ul className="ul">
                  <li>Les néoplasies en Endocrinologie</li>
                  <li>Cancer de la thyroïde, Corticosurrénalome</li>
                  <li>Retards de croissance</li>
                  <li>La mucopolysacaridose</li>
                  <br/>
                </ul>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}