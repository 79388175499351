import React, {useState} from 'react';
import {usePosters} from "../../../hooks/posters";
import {Grid} from "antd";
import PostersMobile from "./postersMobile";
import Posters from "./posters";
import Seo from "../../seo";

const {useBreakpoint} = Grid;

export default function PosterIndex() {
  const screens = useBreakpoint()
  const {posters, loading} = usePosters()
  const [selectedPoster, setSelectedPoster] = useState({
    _id:"",
    title: "",
    authors: "",
    url: "",
    visible: false
  });
  return (
    <div>
      <Seo title="E-Posters"/>
      {screens.xs ?
        <PostersMobile
          data={{posters, loading}}
          selection={{
            selectedPoster,
            setSelectedPoster
          }}
        />
        :
        <Posters
          data={{posters, loading}}
          selection={{
            selectedPoster,
            setSelectedPoster
          }}
        />
      }
    </div>
  );
}