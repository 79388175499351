import React, {useEffect, useState} from 'react';
import {Checkbox, Form, Modal, Radio, Space} from "antd";
import {useInsertAnswer, useQuiz} from "../hooks/answer";

export default function Quiz() {
  const {data} = useQuiz()
  const {postAnswer, loading} = useInsertAnswer()
  const [form] = Form.useForm()
  const [quiz, setQuiz] = useState({
    title: "Choisissez la réponse juste :",
    type: "unique",
    answers: ["A", "B", "C", "D"],
    visible: false
  });
  
  useEffect(() => {
    
    if (data?.quizzes?.length) {
      setQuiz({...data?.quizzes[0], visible: true})
    }else{
      setQuiz({...quiz, visible: false})
    }
  }, [data]);
  
  
  function submit() {
    form.validateFields().then(values => {
      const answers = typeof values.answers === "string" ? [values.answers] : values.answers
      postAnswer(answers, "620ecd194deb8d9f4b509c12").then(() => {
        setQuiz({...quiz, visible: false})
      })
    })
  }
  
  return (
    <Modal
      width="auto"
      title={quiz.title}
      centered
      visible={quiz.visible}
      okText="Soummettre"
      cancelText="Ignorer"
      onOk={submit}
      onCancel={() => setQuiz({...quiz, visible: false})}
      okButtonProps={{loading}}
    
    >
      <Form form={form}>
        <Form.Item name={"answers"}>
          {quiz.type === "unique" ?
            <Radio.Group>
              <Space direction="vertical">
                {quiz.answers.map(answer => <Radio
                  value={answer}>{answer}</Radio>)}
              </Space>
            </Radio.Group>
            :
            <Checkbox.Group>
              <Space direction="vertical">
                {quiz.answers.map(answer => <Checkbox
                  value={answer}>{answer}</Checkbox>)}
              </Space>
            </Checkbox.Group>
          }
        </Form.Item>
      </Form>
    </Modal>
  );
}