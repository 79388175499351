import {useQuery} from "@apollo/client";
import {POSTERS} from "../apollo/queries/posters";

function usePosters() {
  const {
    data,
    loading,
  } = useQuery(POSTERS, {
    variables: {
      sortBy: "TITLE_DESC",
      query: {event: {_id: process.env.GATSBY_EVENT_ID}}
    }
  })
  return {
    posters: data?.posters ?? [],
    loading
  }
}

export {usePosters}