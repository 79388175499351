import {useQuery} from "@apollo/client";
import {LIVE, VIDEOS} from "../apollo/queries/videos";

function useVideos() {
  const {
    data,
    loading,
  } = useQuery(VIDEOS, {
    variables: {
      sortBy: "TITLE_DESC",
      query: {
        event: {_id: process.env.GATSBY_EVENT_ID},
        title_ne: "LIVE"
      }
    }
  })
  
  return {
    videos: data?.videos ?? [],
    loading
  }
}

function useLive() {
  const {data,} = useQuery(LIVE, {
    variables: {
      query: {
        event: {_id: process.env.GATSBY_EVENT_ID},
        title: "LIVE"
      }
    }
  })
  return {url: data?.video?.url ?? "", _id: data?.video?._id ?? ""}
}

export {useVideos, useLive}