import React, {useState} from 'react';
import {useVideos} from "../../../hooks/videos";
import {Grid} from "antd";
import VideosMobile from "./videosMobile";
import Videos from "./videos";
import Seo from "../../seo";
import {useInsertQuestion} from "../../../hooks/questions";

const {useBreakpoint} = Grid;

export default function VideosIndex() {
  const screens = useBreakpoint()
  const {videos, loading} = useVideos()
  
  const {postQuestion, loading: questionLoading, form} = useInsertQuestion()
  const [selectedVideo, setSelectedVideo] = useState({
    _id: "",
    title: "",
    speaker: "",
    url: "",
    visible: false
  });
  
  function sendQuestion() {
    form.validateFields()
      .then(values => {
        postQuestion({
          content: values.content.trim(),
          video: selectedVideo._id
        })
          .then(() => form.resetFields())
      })
  }
  
  return (
    <div>
      <Seo title="Communications"/>
      {screens.xs ?
        <VideosMobile
          data={{videos, loading}}
          selection={{
            selectedVideo,
            setSelectedVideo
          }}
          questions={{
            sendQuestion,
            loading: questionLoading,
            form
          }}
        />
        :
        <Videos
          data={{videos, loading}}
          selection={{
            selectedVideo,
            setSelectedVideo
          }}
          questions={{
            sendQuestion,
            loading: questionLoading,
            form
          }}
        />
      }
    </div>
  );
}