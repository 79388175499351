import React from 'react';
import {Card, Col, Modal, Row, Spin, Typography} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import Iframe from "react-iframe";

const {Title} = Typography
const {Meta} = Card

export default function Posters(props) {
  const {data, selection} = props
  const {posters, loading} = data
  const {selectedPoster, setSelectedPoster} = selection
  
  return (
    <>
      <Row justify="center" gutter={[15, 15]}>
        <Col span={18}>
          <StaticImage
            src="../../../images/posterBanner.png"
            alt="videoBanner"
          />
        </Col>
        <Col sm={18} xs={24}>
          <Row gutter={[15, 15]}>
            {loading ?
              <Spin size="large" style={{color: "white"}}/>
              : posters.map(poster => (
                <Col xs={24} sm={8} key={poster._id}>
                  <Card
                    style={{backgroundColor: "#F3AF4A"}}
                    onClick={() => setSelectedPoster({
                      visible: true,
                      url: poster.url,
                      title: poster.title,
                      speaker: poster.authors
                    })}
                    hoverable
                    cover={
                      <StaticImage
                        src={"../../../images/posterCover.png"}
                        alt={"banner"}
                      />
                    }
                  >
                    <Meta
                      title={poster.title}
                      description={poster.authors}
                    />
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      <Modal
        width={"auto"}
        onCancel={() => setSelectedPoster({...selectedPoster, visible: false})}
        title={<Title level={4}>{selectedPoster.title}</Title>}
        visible={selectedPoster.visible}
        centered
        footer={null}
        destroyOnClose
      >
        <Iframe
          url={selectedPoster.url}
          width={1024}
          height={576}
          allowFullScreen
          scrolling="yes" frameBorder="1"
        />
      </Modal>
    </>
  );
}
