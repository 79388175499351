const session1 = [
  {
    title: "Cœur et Acromégalie",
    author: "PAG L. AHMED ALI, PR S FEDALA, Service Endocrinologie C.H.U Bab EL Oued"
  },
  {
    title: "Dysthyroîdie et Coeur",
    author: "Dr A. ADIMI, Pr S FEDALA Service Endocrinologie C.H.U Bab EL Oued"
  },
  {
    title: "Obésité et retentissement cardio-vasculaire",
    author: "Dr H. DJORANE, Pr AEM HADDAM service de Diabétologie C.H.U Bab El Oued"
  },
  {
    title: "Intérêt de l'impudence-métrie dans l'obésité",
    author: "Dr F. BOUYOUCEF, Pr AEM HADDAM  Service de Diabétologie C.H.U Bab El Oued"
  },
  {
    title: "Les tumeurs neuro-endocrines à travers un cas clinique",
    author: "Dr I. BENOUMECHIARA, Pr S FEDALA Service Endocrinologie C.H.U Bab EL Oued"
  },
  {
    title: "La prise en charge des endocrinopathies au cours de la Covid 19",
    author: "Dr C. BAGHDALI, Pr S FEDALA Service d’Endocirnologie C.H.U Bab El Oued"
  },
  {
    title: "Diabète sucré et COVID19",
    author: "Dr S. BOUHEDOU / Dr H. LEDRA, Pr AEM HADDAM Service de Diabétologie C.H.U Bab El Oued"
  }
]
const table1 = [
  {
    title: "Aspect anatomopathologique des cancers thyroidiens de faible risque",
    author: "Dr BENAHMED - Pr DJENANE, Service d’Anatomopathologique C.H.U Bab El Oued."
  },
  {
    title: "Le diagnostic et la prise en charge post opératoire",
    author: "Dr R. LOUNIS, Pr S FEDALA Service Endocrinologie C.H.U Bab EL Oued"
  },
  {
    title: "Apport de l'échographie cervicale dans le diagnostic et le suivi des cancers thyrïdiens",
    author: "Dr D.E.BOUDIAF,Pr S FEDALA Service Endocrinologie C.H.U Bab El Oued"
  },
  {
    title: "Indication du curage ganglionnaire prophylactique dans le cancer différencié de la thyroïde",
    author: "Dr AMALOU, Service de Chirurgie Général C.H.U de Bab El Oued"
  }
]
const table2 = [
  {
    title: "Le diagnostic et la prise en charge post chirurgicale : quelles actualités en 2021",
    author: "Dr C. BAGHDALI, Pr S FEDALA Service d’Endocrinologie C.H.U Bab El Oued"
  },
  {
    title: "Aspects anatomopathologiques des carcinomes corticosurrénalomes",
    author: "Dr T. TAIR, Dr S. DERKAOUI, Pr N. DJENANE, Service d’Anatomopathologique C.H.U Bab El Oued"
  },
  {
    title: "La  Prise en charge des corticosurrénalomes: stratégie chirurgicale et résultats post opératoire",
    author: "Dr BAHBOUH, Service de chirurgie général, C.H.U Bab EL Oued."
  },
]

const table3 = [
  {
    title: "Place actuelle des sulfamides hypoglycémiants dans la prise en charge du diabétique",
    author: "Dr ZEMALI, Service de Diabétologie C.H.U Bab El Oued"
  },
  {
    title: "Place des insulines modernes dans la prise en charge du diabète type 2",
    author: "Dr S. MAHGOUNE, Service de Diabétologie C.H.U Bab El Oued"
  },
  {
    title: "SGLT2 et Reins",
    author: "Dr M. KESRAOUI, Service de Diabétologie C.H.U Bab El Oued"
  },
  {
    title: "SGLT2 et cœur",
    author: "Dr N. MADANI, Service de Diabétologie C.H.U Bab El Oued"
  },
  {
    title: "Les Biosimilaires",
    author: "Dr Ouguerroudj  , Pr AEM HADDAM Service de Diabétologie C.H.U Bab El Oued"
  },
]
const session3=[
  {
    title: "Ateliers: L’enfant trop petit, quel diagnostic",
    author: "Dr H. SI YOUCEF. Service d’Endocrinologie C.H.U Bab El Oued"
  },
  {
    title: "La prise  en charge du retard de croissance: Quoi de neuf ?",
    author: "Pr N.S. FEDALA. Service d’Endocrinologie C.H.U Bab El Oued"
  },
]
const atelier1=[
  {
    title: "CGM intérêt ; expérience du service de diabétologie",
    author: "Dr M.A. BENYAHI, Dr R.ZERARKA, Service de Diabétologie C.H.U Bab El Oued"
  },
  {
    title: "Pompe à insuline intérêt ; expérience du service de Diabétologie du C.H.U Bab El Oued",
    author: "Dr BELLAL, Dr S. DABOUZ, Dr L. CHIBANI, Service de Diabétologie C.H.U Bab El Oued. "
  },
]
const atelierSanofi=[
  {
    title: "CGM internet : Expérience du service de diabétologie",
    author: "Dr M.A. BENYAHI - Dr R. ZERARKA - Dr L. CHIBANI"
  },
  {
    title: "Pompe à Insuline : Expérience du service de diabétologie",
    author: "Dr BELLAL - Dr S. DABOUZ"
  },
]

const atelierNovonordisk=[]
export {session1,session3, table1, table2, table3,atelier1,atelierSanofi,atelierNovonordisk}