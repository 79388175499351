import React from 'react';
import {List} from "antd";

export default function ProgramList({data}) {
  return (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={data}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={item.title}
            description={item.author}
          />
        </List.Item>
      )}
    />
  );
}