import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Card, List} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import {FileSearchOutlined} from "@ant-design/icons";
import Iframe from "react-iframe";
export default function PostersMobile(props) {
  const {data, selection} = props
  const {posters, loading} = data
  const {selectedPoster, setSelectedPoster} = selection
  
  return (
    <>
      {selectedPoster._id === "" ?
        <StaticImage
          src="../../../images/posterCover.png"
          alt="banner"
          layout="fixed"
          style={{width: "100%"}}
        />
        :
        <Iframe
          url={selectedPoster.url}
          width="100%"
          height={400}
          allowFullScreen
          scrolling="yes" frameBorder="1"
        />
      }
      <Card
        title="E-Posters"
        loading={loading}
      >
        <div
          id="scrollableDiv"
          style={{
            height: 500,
            overflow: 'auto',
          }}
        >
          <InfiniteScroll
            dataLength={posters.length}
            scrollableTarget="scrollableDiv"
          >
            <List
              itemLayout="horizontal"
              dataSource={posters}
              renderItem={item => (
                <List.Item
                  key={item._id}
                  onClick={() => setSelectedPoster({
                    _id: item._id,
                    title: item.title,
                    url: item.url,
                    authors: item.authors,
                    visible: true
                  })}
                  style={selectedPoster._id === item._id ? {backgroundColor: "rgba(0,0,0,0.1)"} : {}}
                >
                  <List.Item.Meta
                    avatar={<FileSearchOutlined/>}
                    title={item.title}
                    description={item.authors}
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </Card>
    </>
  );
}