import React from 'react';
import {Col, Grid, Layout, Row,Typography} from "antd";
import HeaderContent from "./header";
import MobileHeader from "./mobileHeader";
const {Text}=Typography
const {Header, Content} = Layout
const {useBreakpoint} = Grid;
export default function DesktopLayout(props) {
  const screens = useBreakpoint()
  const {children} = props
  
  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100vw",
        }}
      >
        {screens.xs ? <MobileHeader/> :
          <Row style={{width: "100vw"}} justify="center">
            <Col span={15}>
              <HeaderContent mode="horizontal"/>
            </Col>
          </Row>}
      </Header>
      <Content>
        <div style={{height: "70px"}}/>
        {children}
        <br/>
        <div style={{margin: 25, textAlign: "center",width:"100%"}}>
          <Text>
            Propulsé par: Lotus Medias
          </Text>
        </div>
      </Content>
    </Layout>
  );
}