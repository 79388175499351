import React, {useState} from 'react';
import {MenuOutlined} from "@ant-design/icons";
import {Button, Drawer} from "antd";
import HeaderContent from "./header";

export default function MobileHeader() {
  const [visible, setVisible] = useState(false);
  
  function toggleDrawer() {
    setVisible(!visible)
  }
  
  return (
    <div>
      <Button
        type="link"
        style={{color: "white"}}
        icon={<MenuOutlined/>}
        onClick={toggleDrawer}
        size="large"
      />
      <Drawer
        width={230}
        placement="left"
        onClose={toggleDrawer}
        visible={visible}
        closable={false}
      >
        <HeaderContent mode="inline"/>
      </Drawer>
    </div>
  );
}