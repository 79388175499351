import {gql} from "@apollo/client";

const VIDEOS = gql`
    query videos($sortBy:VideoSortByInput!,$query:VideoQueryInput){
        videos(sortBy:$sortBy,query:$query){
            _id
            title
            url
            speakers{
                name
            }
        }
    }
`

const LIVE=gql`
    query video($query:VideoQueryInput){
        video(query:$query){
            _id
            url
        }
    }
`
export {VIDEOS,LIVE}