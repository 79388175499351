import {useMutation, useQuery} from "@apollo/client";
import {message} from "antd";
import {INSERT_ANSWER} from "../apollo/mutations/answers";
import { QUIZZES} from "../apollo/queries/quiz";


function useQuiz() {
  const {data} = useQuery(QUIZZES, {
    variables: {query: {status: "online"}},
  })
  return {data}
}

function useInsertAnswer() {
  const [inserOneAnswer, {loading}] = useMutation(INSERT_ANSWER)
  
  async function postAnswer(answers, quiz) {
    try {
      await inserOneAnswer({
        variables: {
          data: {
            quiz: {link: quiz},
            answers,
          },
        },
      })
      message.success("Votre réponse a été envoyée", 2)
      return true
    } catch (e) {
      console.log(e)
      message.error("erreur dans l'envoi", 2)
      return false
    }
  }
  
  return {
    loading,
    postAnswer,
  }
}

export {useInsertAnswer, useQuiz}