import {gql} from "@apollo/client";

const POSTERS = gql`
    query posters($sortBy:PosterSortByInput!,$query:PosterQueryInput){
        posters(sortBy:$sortBy,query:$query){
            _id
            title
            authors
            url
        }
    }
`

export {POSTERS}