import {useMutation} from "@apollo/client";
import {INSERT_QUESTION} from "../apollo/mutations/questions";
import {Form, message} from "antd";
import {useLocalStorage} from "react-use";

function useInsertQuestion() {
  const [insertOneQuestion, {loading}] = useMutation(INSERT_QUESTION)
  const [value] = useLocalStorage("userId")
  const [form] = Form.useForm()
  
  async function postQuestion(question) {
    try {
      await insertOneQuestion({
        variables: {
          data: {
            event: {link: process.env.GATSBY_EVENT_ID},
            content: question.content,
            state: "new",
            video: {link: question.video},
            questioner: {link: value},
            anonymous: false,
          },
        },
      })
      message.success("Votre question a été envoyé", 2)
      return true
    } catch (e) {
      console.log(e)
      message.error("erreur dans l'envoi", 2)
      return false
    }
  }
  
  return {
    loading,
    postQuestion,
    form
  }
}

export {useInsertQuestion}