import React, {useState} from 'react';
import {Modal, Space} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image"
import {graphql, useStaticQuery} from "gatsby";

export default function Airp() {
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "Airp.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  const [selectedImage, setSelectedImage] = useState({
    title: "",
    visible: false
  });
  
  function showBanner(title) {
    setSelectedImage({
      title,
      visible: true
    })
  }
  
  return (
    <BackgroundImage
      fluid={imageData}
      style={{minHeight: 460}}
    >
      <Space direction="vertical" align="center"
             style={{width: "100%", marginTop: "55%"}}>
        <StaticImage src="./Airp_basa.png" alt="basa"
                     onClick={() => showBanner("Basalog")}/>
        <StaticImage src="./Airp_edu.png" alt="edu"
                     onClick={() => showBanner("Education")}/>
      </Space>
      <Modal
        title={<p>{selectedImage.title}</p>}
        centered
        width={400}
        visible={selectedImage.visible}
        onCancel={() => setSelectedImage({...selectedImage, visible: false})}
        footer={null}
      >
        {selectedImage.title === "Basalog" &&
          <StaticImage src="./basalog.png" alt="soma"/>}
        {selectedImage.title === "Education" &&
          <StaticImage src="./education.png" alt="deca"/>}
      
      </Modal>
    
    </BackgroundImage>
  );
}