import React, {useEffect} from 'react';
import ReactPlayer from "react-player";
import {Affix, Button, Card, Form, List, Modal, Popover, Space} from "antd";
import {
  PlayCircleOutlined,
  QuestionOutlined,
  SendOutlined
} from "@ant-design/icons"
import TextArea from "antd/es/input/TextArea";
import InfiniteScroll from "react-infinite-scroll-component";
import {StaticImage} from "gatsby-plugin-image";
import {useSessionStorage} from "react-use";

export default function VideosMobile(props) {
  const [value, setValue] = useSessionStorage("questions")
  const {data, selection, questions} = props
  const {videos, loading} = data
  const {selectedVideo, setSelectedVideo} = selection
  const {form, loading: questionLoading, sendQuestion} = questions
  useEffect(() => {
    if (selectedVideo._id !== "") {
      if (!value) {
        Modal.info({
          width: "95%",
          content: "Vous pouvez poser vos question en cliquant sur le point d'interrogation en bas droit de votre écrans.",
          footer: null,
          centered: true,
          onOk: () => setValue("ok")
        })
      }
    }
  }, [selectedVideo]);
  
  return (
    <>
      {selectedVideo._id === "" ?
        <StaticImage
          src="../../../images/videoCover.png"
          alt="banner"
          layout="fixed"
          style={{width: "100%"}}
        />
        :
        <ReactPlayer
          url={selectedVideo.url}
          controls
          width="100%"
        />
      }
      <Card
        title="Communications"
        loading={loading}
      >
        
        <div
          id="scrollableDiv"
          style={{
            height: 500,
            overflow: 'auto',
          }}
        >
          <InfiniteScroll
            dataLength={videos.length}
            scrollableTarget="scrollableDiv"
          >
            <List
              itemLayout="horizontal"
              dataSource={videos}
              renderItem={item => (
                <List.Item
                  key={item._id}
                  onClick={() => setSelectedVideo({
                    _id: item._id,
                    title: item.title,
                    url: item.url,
                    speaker: item.speakers.map(speaker => speaker.name).join(", "),
                    visible: true
                  })}
                  style={selectedVideo._id === item._id ? {backgroundColor: "rgba(0,0,0,0.1)"} : {}}
                >
                  <List.Item.Meta
                    avatar={<PlayCircleOutlined/>}
                    title={item.title}
                    description={item.speakers.map(speaker => speaker.name).join(", ")}
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </Card>
      {selectedVideo._id !== "" &&
        <Affix style={{position: 'fixed', bottom: 30, right: 30}}>
          <Popover
            placement="topRight"
            content={
              <Space style={{width: "80vw"}} direction="vertical">
                <Form form={form}>
                  <Form.Item
                    name="content"
                    rules={[
                      {
                        whitespace: true,
                        message: "Veuillez saisir une question!",
                      },
                    ]}
                  >
                    <TextArea rows={3} placeholder="Poser votre question ici"/>
                  </Form.Item>
                </Form>
                <Button
                  type="primary"
                  block
                  size="large"
                  icon={<SendOutlined/>}
                  loading={questionLoading}
                  onClick={sendQuestion}
                >
                  Envoyer
                </Button>
              </Space>
            }
            title="Questions"
            trigger="click"
          >
            <Button
              type="primary"
              shape="circle"
              icon={<QuestionOutlined/>}
              size="large"
              style={{boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)"}}
            />
          </Popover>
        </Affix>
      }
    
    </>
  );
}