import React, {useState} from "react"
import {Menu} from "antd"
import {Link} from "gatsby"

const {SubMenu} = Menu
export default function HeaderContent(props) {
  const {mode} = props
  const [selectedKey, setSelectedKey] = useState("/")
  
  return (
    <Menu
      theme="dark"
      style={{
        fontSize: 17,
        height: "100%",
      }}
      mode={mode}
      selectedKeys={[selectedKey]}
      onSelect={({key}) => setSelectedKey(key)}
    >
      <Menu.Item key="/">
        <Link to="/">Accueil</Link>
      </Menu.Item>
      <Menu.Item key="/programme">
        <Link to={"/programme"}>Programme</Link>
      </Menu.Item>
      <Menu.Item key="/orateurs">
        <Link to={"/orateurs"}>Orateurs</Link>
      </Menu.Item>
      <Menu.Item key="/communications">
        <Link to="/communications">Communications</Link>
      </Menu.Item>
      <Menu.Item key="/live">
        <Link to="/live">Rediffusion</Link>
      </Menu.Item>
      <Menu.Item key="/posters">
        <Link to={"/posters"}>E-Posters</Link>
      </Menu.Item>
      <SubMenu
        key="/expo"
        title="Exposition"
      >
        <Menu.Item key="/expo/sanofi">
          <Link to="/expo/sanofi">Sanofi</Link>
        </Menu.Item>
        <Menu.Item key="/expo/novonordisk">
          <Link to="/expo/novonordisk">Novo Nordisk</Link>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="/expo/airp">
          <Link to="/expo/silver">AIRP</Link>
        </Menu.Item>
        <Menu.Item key="/expo/ipsen">
          <Link to="/expo/silver">IPSEN</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/expo/silver">SANDOZ</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/expo/silver">Merck</Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/partenaires">
        <Link to={"/partenaires"}>Partenaires</Link>
      </Menu.Item>
    </Menu>
  )
}