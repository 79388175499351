import {gql} from "@apollo/client";

const INSERT_ANSWER = gql`
    mutation insertOneAnswer($data:AnswerInsertInput!){
        insertOneAnswer(data:$data){
            _id
        }
    }
`

export {INSERT_ANSWER}