import React from 'react';
import {Col, Row} from "antd";
import Airp from "./airp/airp";
import Ipsen from "./ipsen/ipsen";
import Sandoz from "./sandoz/sandoz";
import Merck from "./merck/merck";

export default function Silver() {
  
  return (
    <Row justify="center" gutter={[15, 15]}>
      <Col sm={18} xs={23}>
        <Row gutter={[15, 15]} align="center">
          <Col xs={24} sm={10}>
            <Airp/>
          </Col>
          <Col xs={24} sm={10}>
            <Ipsen/>
          </Col>
          <Col xs={24} sm={10}>
            <Sandoz/>
          </Col>
          <Col xs={24} sm={10}>
            <Merck/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}