import {useLazyQuery} from "@apollo/client";
import {LOGIN} from "../apollo/queries/login";
import {useLocalStorage} from "react-use";
import {message} from "antd";
import {userId} from "../apollo/reactiveVars";


function useLogin() {
  const [getLogin, { loading}] = useLazyQuery(LOGIN)
  const [value,setValue] = useLocalStorage("userId")
  
  function login(email) {
    getLogin({variables: {input: {event: process.env.GATSBY_EVENT_ID, email}}})
      .then(({data}) => {
        if (data.login) {
          setValue(data?.login._id)
          userId(data?.login._id)
          message.success("Bienvenu " + data?.login.name, 2)
        }else{
          message.error("cet e-mail n'existe pas", 2)
        }
      }).catch(e => {
      console.log(e)
    })
  }
  
  return {
    login,
    loading
  }
}

export {useLogin}