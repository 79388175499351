import {useQuery} from "@apollo/client";
import {SPEAKERS} from "../apollo/queries/speakers";

function useSpeakers() {
  const {
    data,
    loading,
  } = useQuery(SPEAKERS, {
    variables: {
      sortBy: "NAME_DESC",
      query: {event: {_id: process.env.GATSBY_EVENT_ID}}
    }
  })
  return {
    speakers: data?.speakers ?? [],
    loading
  }
}

export {useSpeakers}