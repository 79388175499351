import React from 'react';
import {Button, Card, Col, Form, Input, Row, Tag} from "antd";
import {useLogin} from "../hooks/login";
import {Link} from "gatsby";


export default function Login() {
  const {login, loading} = useLogin()
  const [form] = Form.useForm()
  
  function connect() {
    form.validateFields()
      .then(values => {
        login(values.email.trim())
      })
      .catch(e => {
        console.log(e)
      })
  }
  
  return (
    <Row justify="center" align="middle" style={{height: "100vh"}}>
      <Col xs={23}sm={8}>
        <Card>
          <Form
            form={form}
          >
            <Form.Item
              label=" "
              colon={false}
              requiredMark={""}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir l'e-mail!",
                },
                {
                  type: "email",
                  message: "Veuillez saisir un e-mail valide!",
                },
              ]}
            >
              <Input onPressEnter={connect} placeholder="Email"/>
            </Form.Item>
            <Form.Item>
              <Button
                block
                onClick={connect}
                type="primary"
                loading={loading}
              >
                Connexion
              </Button>
            </Form.Item>
            <Form.Item>
              <div style={{textAlign:"center"}}>
                Pas inscrit? <Link to={"/inscription"}><Tag color="#108ee9">Inscrivez vous!</Tag></Link>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}