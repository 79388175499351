import React from 'react';
import {Timeline, TimelineItem} from 'vertical-timeline-component-for-react';
import {Card, Col, Divider, Row} from "antd";
import "../../styles/timeline.css"
import {
  atelier1,
  session1,
  session3,
  table1,
  table2,
  table3
} from "../../utils/program";
import ProgramList from "./program/programList";
import Seo from "../seo";

export default function Program() {
  return (
    <Row justify="center">
      <Seo title="Programme"/>
      <Col sm={16} xs={24}>
        <Timeline className="react-timeline">
          <TimelineItem
            key="001"
            dateText="Allocution de bienvenu: 9:30 - 9:45"
            dateInnerStyle={{background: '#bdd6ee'}}
          >
            <Card className="card">
              <h3> Pr. A.E.M. HADDAM, Dr. D.E. BOUAB.
                Président de la Société Algérienne Libérale de Diabétologie et D’Endocrinologie.
                Chef de service de Diabétologie – C.H.U de Bab El Oued.
              </h3>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="002"
            dateText="1ère SEANCE: 9:45 - 10:30"
            dateInnerStyle={{background: '#bdd6ee'}}
          >
            <Card className="card">
              <h3>Coeur, Hormones, Tumeurs Neuro-Endocrines, Covid 19,
                Endocrinopathies et Maladies Métaboliques.</h3>
              <h5>MODERATEURS : Pr. N.S. FEDALA, Pr. A. BOUZID, Dr. D.E. BOUAB.</h5>
              <Divider/>
              <ProgramList data={session1}/>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="003"
            dateText="1ère TABLE RONDE: 10:30 - 11:00"
            dateInnerStyle={{background: '#a8d08d', color: '#000'}}
          >
            <Card className="card">
              <h3>
                Les Cancers Thyroïdiens A Faible Risque, Qu'est Ce Qui a Changé?
              </h3>
              <h5>MODERATEURS : Pr. N.S.FEDALA,  Dr. N. DJENANE, Pr A BOUZID
                Pr. A. OUSLIMANI. Dr. D.E. BOUAB.
              </h5>
              <Divider/>
              <ProgramList data={table1}/>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="004"
            dateText="2ème TABLE RONDE : 11:00 - 11:30"
            dateInnerStyle={{background: '#a8d08d', color: '#000'}}
          >
            <Card className="card">
              <h3>
                Le Corticosurrénalome: Une Prise En Charge Multidisciplinaire
              </h3>
              <h5>
                MODERATEURS : Pr. N.S. FEDALA, Pr. N. DJENANE,
                Pr. A. OUSLIMANI. Dr. D.E. BOUAB.
              </h5>
              <Divider/>
              <ProgramList data={table2}/>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="005"
            dateText="3ème TABLE RONDE : 11:30 - 12:00"
            dateInnerStyle={{background: '#a8d08d', color: '#000'}}
          >
            <Card className="card">
              <h3>
                Les Nouvelles Thérapeutiques dans le Diabète Sucré Selon Les
                Recommandations des Sociétés Savantes
              </h3>
              <h5>MODERATEURS :  Pr. A. BENZIANE, Pr. S.AZZOUG, Dr. D.E. BOUAB, Pr. A.E.M. HADDAM
              </h5>
              <Divider/>
              <ProgramList data={table3}/>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="006"
            dateText="Symposium : 12:30 - 13:00 "
            dateInnerStyle={{background: '#ffe599', color: '#000'}}
          >
            <Card>
              <h3>
                Novonordisk: L’HYPOGLYCEMIE en intensification: comment réduire
                le risque?
              </h3>
              <h5>
                MODERATEURS :  Pr. A.E.M. HADDAM, Dr. D.E. BOUAB. Pr. N.S. FEDALA
              </h5>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="007"
            dateText="2ème SEANCE: 14:30 - 15:00"
            dateInnerStyle={{background: '#bdd6ee'}}
          >
            <Card className="card">
              <h3>
                Nouvelles Recommandations de l'ADA 2022 dans la prise en
                charge du diabètique
              </h3>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="008"
            dateText="Symposium: 15:00 - 15:30 "
            dateInnerStyle={{background: '#ffe599', color: '#000'}}
          >
            <Card className="card">
              <h3>Symposium SANOFI  TOUJEO- DR AOUICHE</h3>
              <h5>MODERATEURS : Pr. N.S. FEDALA,  Pr. A.E.M. HADDAM, Dr. D.E. BOUAB.
              </h5>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="009"
            dateText="3ème SEANCE: 15:30 - 16:15"
            dateInnerStyle={{background: '#bdd6ee'}}
          >
            <Card className="card">
              <h3>
                Croissance Et Métabolisme
              </h3>
              <Divider/>
              <ProgramList data={session3}/>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="010"
            dateText="Ateliers: 16:15 - 17:00 "
            dateInnerStyle={{background: '#f4cccc', color: '#000'}}
          >
            <Card className="card">
              <h3>Atelier Sanofi</h3>
              <h4>Utilisation des nouvelles technologies durant la grossesse PAg. H. BAGHOUS</h4>
              <Divider/>
              <h3>Nouvelles Technologies en Diabétologie</h3>
              <Divider/>
              <ProgramList data={atelier1}/>
            </Card>
          </TimelineItem>
          <TimelineItem
            key="011"
            dateText="Ateliers Novonordisk: 17:00 - 17:20"
            dateInnerStyle={{background: '#f4cccc', color: '#000'}}
          >
            <Card className="card">
              <h3>Ateliers Diabete: Novomix</h3>
              <h4>PR AEM HADDAM
              </h4>
            </Card>
          </TimelineItem>
        </Timeline>
      </Col>
    </Row>
  );
}