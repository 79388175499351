import React, {useState} from 'react';
import {Modal, Space} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import BackgroundImage from "gatsby-background-image"

export default function Merck() {
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "Merck.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  const [selectedImage, setSelectedImage] = useState({
    title: "",
    visible: false
  });
  
  function showBanner(title) {
    setSelectedImage({
      title,
      visible: true
    })
  }
  
  return (
    <BackgroundImage
      fluid={imageData}
      style={{minHeight: 460}}
    >
      <Space direction="vertical" align="center" style={{width: "100%",marginTop: "55%"}}>
        
        <StaticImage src="./Merck_Gluc.png" alt="basa"
                     onClick={() => showBanner("Glucophage")}/>
        <StaticImage src="./Merck_levo.png" alt="edu"
                     onClick={() => showBanner("Levothyrox")}/>
      </Space>
      <Modal
        title={<p>{selectedImage.title}</p>}
        centered
        width={400}
        visible={selectedImage.visible}
        onCancel={() => setSelectedImage({...selectedImage, visible: false})}
        footer={null}
      >
        {selectedImage.title === "Glucophage" &&
          <StaticImage src="./Gluco.png" alt="soma"/>}
        {selectedImage.title === "Levothyrox" &&
          <StaticImage src="./levo.png" alt="deca"/>}
      </Modal>
    </BackgroundImage>
  );
}