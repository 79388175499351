import React from 'react';
import {Router} from "@reach/router";
import DesktopLayout from "../layout/layout";
import Program from "../components/pages/program";
import Speakers from "../components/pages/speakers";
import Home from "../components/pages/home";
import Videos from "../components/pages/videos";
import Posters from "../components/pages/posters";
import Live from "../components/pages/live";
import Partners from "../components/pages/partners";
import {useLocalStorage} from "react-use";
import Login from "../components/login";
import {useReactiveVar} from "@apollo/client";
import {userId} from "../apollo/reactiveVars";
import Sanofi from "../components/pages/expo/sanofi/sanofi";
import Novonordisk from "../components/pages/expo/novonordisk/novonordisk";
import Silver from "../components/pages/expo/silver/silver";

const Wrapper = ({children}) => {
  return children
}

const RequireLoggedInUser = ({children}) => {
  // Only render children if there is a logged in user.
  const [value] = useLocalStorage("userId");
  const watchUserId = useReactiveVar(userId)
  return watchUserId || value ? children : <Login/>;
};

export default function Index() {
  return (
    <RequireLoggedInUser>
      <DesktopLayout>
        <Router basepath="/">
          <Home path="/"/>
          <Speakers path="orateurs"/>
          <Program path="programme"/>
          <Videos path="communications"/>
          <Posters path="posters"/>
          <Live path="live"/>
          <Wrapper path="expo">
            <Partners path="/"/>
            <Sanofi path="sanofi"/>
            <Novonordisk path="novonordisk"/>
            <Silver path="silver"/>
          </Wrapper>
          <Partners path="partenaires"/>
        </Router>
      </DesktopLayout>
    </RequireLoggedInUser>
  );
}