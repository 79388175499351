import React, {useState} from 'react';
import "../../../../styles/player.css"
import ReactPannellum from "react-pannellum";
import sanofi from "./Sanofi.jpg"
import preview from "./sanofi-min.jpg"
import {Card, Col, Grid, List, Modal, Row} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import {PlayCircleOutlined} from "@ant-design/icons";
import Seo from "../../../seo";

const {useBreakpoint} = Grid
const urls = [
  {
    title: "Toujeo",
    url: "https://jed-beo.visiocare.org/stands/sanofi/toujeo.mp4"
  },
  {
    title: "Pharmacie Corner",
    url: "https://jed-beo.visiocare.org/stands/sanofi/corner-pharmacie.mp4"
  },
  {
    title: "Medeo",
    url: "https://jed-beo.visiocare.org/stands/sanofi/teaser-medeo.mp4"
  },
  {
    title: "Cliniques Mobile",
    url: "https://jed-beo.visiocare.org/stands/sanofi/clinique-mobile.mp4"
  }
]

export default function Sanofi() {
  const screens = useBreakpoint()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("https://jed-beo.visiocare.org/stands/sanofi/toujeo.mp4");
  
  function showContent(content) {
    setContent(content)
    setIsModalVisible(true)
  }
  
  function getModalWidth() {
    if (screens.xs) {
      return "100%"
    } else {
      switch (content) {
        case "Apidra":
          return "35%";
        case "Toujeo":
          return "65%"
        default:
          return 1500
      }
    }
  }
  
  return (
    <>
      <Seo title="Sanofi"/>
      <ReactPannellum
        id="1"
        sceneId="firstScene"
        imageSource={sanofi}
        config={{
          //hotSpotDebug: true,
          autoRotate: false,
          autoLoad: true,
          showControls: false,
          preview: preview,
          //hfov: 130,
          hotSpots: [
            {
              type: "custom",
              pitch: -0.2742076913076599,
              yaw: 16.36657134529176,
              text: "Apidra",
              clickHandlerFunc: () => showContent("Apidra"),
              cssClass: "linkHotSpot"
            },
            {
              type: "custom",
              pitch: -0.2742076913076599,
              yaw: -15.464482405035769,
              text: "Toujeo",
              cssClass: "linkHotSpot",
              clickHandlerFunc: () => showContent("Toujeo"),
            },
            {
              type: "custom",
              pitch: 0.04063510863807973,
              yaw: 1.219145457685685,
              text: "Vidéos",
              cssClass: "playHotSpot",
              clickHandlerFunc: () => showContent("Video"),
            }
          ]
        }}
        autoLoad={true}
        style={{
          width: "100%",
          height: "92vh"
        }}
      />
      <Modal
        title={<p>{content}</p>}
        centered
        width={getModalWidth()}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose={content === "Video"}
      >
        {content === "Apidra" &&
          <StaticImage src="./apidra-min.jpg" alt="apidra"/>}
        {content === "Toujeo" &&
          <StaticImage src="./toujeo-min.jpg" alt="toujeo"/>}
        {content === "Video" &&
          
          <Row gutter={10}>
            <Col xs={24} sm={17}>
              <ReactPlayer width="100%" height="100%" controls url={url}
                           playing/>
            </Col>
            <Col xs={24} sm={7}>
              <Card style={{height:"100%"}}>
                <List
                  dataSource={urls}
                  renderItem={item => (
                    <List.Item
                      style={url === item.url ? {backgroundColor: "rgba(0,0,0,0.1)"} : {}}
                      onClick={() => setUrl(item.url)}
                    >
                      <List.Item.Meta
                        avatar={<PlayCircleOutlined/>}
                        title={item.title}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        }
      </Modal>
    </>
  );
}
