import React from 'react';
import {Card, Col, Input, Row, Space} from "antd";
import ReactPlayer from "react-player";
import "../../styles/player.css"
import Seo from "../seo";
import Quiz from "../quiz";

const {TextArea} = Input

export default function Live() {
  // const {url, _id} = useLive()
  // const {postQuestion, loading, form} = useInsertQuestion()
  
  // function sendQuestion() {
  //   form.validateFields()
  //     .then(values => {
  //       postQuestion({
  //         content: values.content.trim(),
  //         video: _id
  //       })
  //         .then(() => form.resetFields())
  //     })
  // }
  
  return (
    <>
      <Row justify="center">
        <Seo title="Live"/>
        <Col sm={15} xs={23}>
          <Space direction="vertical" style={{width:"100%"}}>
            <Card
              title="Seance Plénière Matin: Session 1"
            >
              <div className='player-wrapper'>
                <ReactPlayer
                  className='react-player'
                  url="https://vimeo.com/680798791"
                  width="100%"
                  height="100%"
                  controls
                />
              </div>
            </Card>
            <Card
              title="Seance Plénière Matin: Session 2"
            >
              <div className='player-wrapper'>
                <ReactPlayer
                  className='react-player'
                  url="https://vimeo.com/680802904"
                  width="100%"
                  height="100%"
                  controls
                />
              </div>
            </Card>
            <Card
              title="Seance Plénière Après Midi"
            >
              <div className='player-wrapper'>
                <ReactPlayer
                  className='react-player'
                  url="https://vimeo.com/679125150"
                  width="100%"
                  height="100%"
                  controls
                />
              </div>
            </Card>
          </Space>
        </Col>
      </Row>
      <Quiz/>
    </>
  );
}