import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Typography
} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import ReactPlayer from "react-player"
import {SendOutlined} from "@ant-design/icons"
import "../../../styles/player.css"
import "../../../images/videoBanner.png"
const {Meta} = Card
const {Title} = Typography
const {TextArea} = Input
export default function Videos(props) {
  const {data, selection, questions} = props
  const {videos, loading} = data
  const {selectedVideo, setSelectedVideo} = selection
  const {form, loading: questionLoading, sendQuestion} = questions
  return (
    <>
      <Row justify="center" gutter={[15, 15]}>
        <Col span={18}>
          <StaticImage
            src="../../../images/videoBanner.png"
            alt="videoBannner"
          />
        </Col>
        <Col span={18}>
          <Row gutter={[15, 15]}>
            {loading ?
              <Spin size="large" style={{color: "white"}}/>
              : videos.map(video => (
                <Col xs={24} sm={8} key={video._id}>
                  <Card
                    style={{backgroundColor:"#F3AF4A",height: "100%"}}
                    onClick={() => setSelectedVideo({
                      _id:video._id,
                      visible: true,
                      url: video.url,
                      title: video.title,
                      speaker: video.speakers.map(speaker=>speaker.name).join(", ")
                    })}
                    hoverable
                    cover={
                      <StaticImage
                        src={"../../../images/videoCover.png"}
                        alt={"banner"}
                      />
                    }
                  >
                    <Meta title={video.title} description={video.speakers.map(speaker=>speaker.name).join(", ")}/>
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      <Modal
        width={1024}
        onCancel={() => setSelectedVideo({...selectedVideo, visible: false})}
        title={<Title level={4}>{selectedVideo.title}</Title>}
        visible={selectedVideo.visible}
        centered
        footer={null}
        destroyOnClose
      >
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={selectedVideo.url}
            controls
            width="100%"
            height="100%"
          />
        </div>
        <Card>
          <Row gutter={10}>
            <Col flex="auto">
              <Form form={form}>
                <Form.Item
                  name="content"
                  rules={[
                    {
                      whitespace: true,
                      message: "Veuillez saisir une question!",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Poser votre question"
                    style={{width: "100%"}}
                    rows={3}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col>
              <Button
                type="primary"
                size="large"
                icon={<SendOutlined/>}
                loading={questionLoading}
                onClick={sendQuestion}
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
}